/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.esign-btn {
  background-color: #97ab3b;
  color: #fafafa;
  text-align: center;
}
.p {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.success-style{
  color:#97ab3b;
  background-color:#c8d686;
  border-color: #721c2426;
  border-style: solid;
  border-left: 5px solid #97ab3b;
  max-width: 55vw ;
}
::ng-deep.mat-raised-button.mat-button-disabled {
  border-radius: 0 !important;
  min-width:53px !important;
}
@media screen and (max-width: 776px) {

  .mat-typography{
    overflow-y: scroll !important;
  }


}
@media screen and (max-width: 533px) {

  .mat-typography{
    font: 400 12x/20px Roboto, "Helvetica Neue", sans-serif;
  }
  .esign-btn{
      font-size: 2vw;
  }
}


